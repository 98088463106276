import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from 'semantic-ui-react';
import { Paper, TextField, Checkbox } from '@material-ui/core';
import Countdown, { zeroPad } from "react-countdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './test-design.css';
import startimage from '../../../src/image/start-test.jpg'
import koenig_logos from '../../../src/image/koenig-white.png'
import qubites_logos from '../../../src/image/qubitsp-banner.webp'

import socket from '../Reusable/Utility/Socket';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { Interweave } from 'interweave';
import { Center } from 'devextreme-react/map';

const axios = require('axios');

const TrainerSubjectiveTest = () => {

    const [main_loader, set_main_loader] = React.useState(false);
    const [isLive, set_isLive] = React.useState(false);
    const [isTestStarted, setisTestStarted] = React.useState(false);
    const [IsTestFinished, setIsTestFinished] = React.useState(false);
    const [ClientId, setClientId] = React.useState('');
    const [AllSubjective, setAllSubjective] = React.useState([]);

    const [IsValidEmail, setIsValidEmail] = React.useState(false);
    const [LoginType, setLoginType] = React.useState('O');
    const [HeadingText, setHeadingText] = React.useState('');
    const { TestId } = useParams();

    React.useEffect(() => {
        RenderEmailContent();
        //GetTimerOption();
    }, []);

    const EmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const GetTestDetailById = (TId, CId) => {
        axios.get('/Nishant/Qubits/GetAllSubjectiveQuestion?Type=3&TestId=' + TId).then(function (response) {
            if (response.data != null) {
                if (response.data[0].IsTestStartedByTrainer) {
                    GetAllSubjectiveQuestion(TId, CId)
                    setLoginType(response.data[0].LoginType)
                    setHeadingText(response.data[0].CourseName != "" ? response.data[0].CourseName : (response.data[0].PositionName != '' ? response.data[0].PositionName : ''))
                    setisTestStarted(true)
                }
                else {
                    set_isLive(true);
                }
            }
        })
    }

    const RenderEmailContent = () => {
        ReactDOM.render(ShowEmailContent(), document.getElementById('MainContent'))
    }

    const ShowEmailContent = () => {
        return (
            <>
                <Paper className="email-test" style={{ margin: 10, }}>
                    <div className="row">
                        <div className="col-md-6 image-side">
                            <img src={startimage} />
                        </div>
                        <div className="col-md-6 form-side">
                            <TextField id="txtEmail"
                                error={IsValidEmail}
                                label="Enter your email id"
                                helperText={IsValidEmail ? "Invalid Email id" : ""}
                                variant="outlined" />

                            <Button color='blue' size="small" className="mt-3" onClick={() => VerifyEmail()}>
                                Start Test
                            </Button>

                            <Button color='blue' disabled={!isTestStarted} className="mt-2 mb-2 send-report-btn" size="small" onClick={() => {
                                SendStatusToStatus()
                            }}>
                                Send Me My Report
                            </Button>
                        </div>
                    </div>
                </Paper>
            </>
        )
    }

    const VerifyEmail = () => {
        var b = document.getElementById('txtEmail').value;
        if (EmailRegex.test(b)) {
            setIsValidEmail(false)
            setClientId(b);
            GetTestDetailById(TestId, b);
            ReactDOM.render(<p>Welcome <b>{b}</b></p>, document.getElementById('MainContent'))
            
            setInterval(() => {
                GetAllSubjectiveQuestion(TestId, b)
            }, 1000);
        }
        else { setIsValidEmail(true); toast.error('Please Enter Email Id..') }
    }

    const GetAllSubjectiveQuestion = (TId, CId) => {
        var obj = {
            Type: 7,
            TId: TId,
            empId: CId,
        };
        axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionForClientPost', obj).then(function (response) {
            if (response.data.length > 0) {
                setIsTestFinished(response.data[0].IsTestFinished); setAllSubjective(response.data);
            }
        })
    }

    // const GetTimerOption = () => {
    //     socket.on('outgoing data', data => {
    //         if (data.num != '' || data.num != 0) ReactDOM.render(<>Time Left: <Countdown date={Date.now() + data.num * 60000} renderer={renderer} /></>, document.getElementById('spTimer'))
    //         else ReactDOM.render('', document.getElementById('spTimer'))
    //     });
    // }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return ReactDOM.render('Time Over', document.getElementById('spTimer'));
        } else {
            minutes = minutes + hours * 60;
            return (
                <span style={{ color: '#000', fontSize: 30, fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center' }}>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const SubmitAnswer = (QId) => {
        if (ClientId != "") {

            const element = document.getElementById('txtAnswer_' + QId);
            let value = null;

            if (element) {
                value = element.value;
            } else {
                value = "";
            }
            // let Answertext = document.getElementById('txtAnswer_' + QId).value;
            let Answertext = value;
            let IsMcq = document.getElementById('IsMcq_' + QId).value;
            let IsMulti = document.getElementById('IsMulti_' + QId).value;
            let optionA = document.getElementById('Chk1_' + QId).checked;
            let optionB = document.getElementById('Chk2_' + QId).checked;
            let optionC = document.getElementById('Chk3_' + QId).checked;
            let optionD = document.getElementById('Chk4_' + QId).checked;

            let selectedOption = document.querySelector(`input[name="${QId}"]:checked`);
            console.log(Answertext, IsMcq, IsMulti, optionA, optionB, optionC, optionD, selectedOption)

            if (Answertext != "" && ClientId != "") {
                var obj = {
                    TId: TestId,
                    QId: QId,
                    empId: ClientId,
                    Ctext: Answertext,
                    isMcq: IsMcq,
                    isMulti: IsMulti,
                    OptionA: optionA,
                    OptionB: optionB,
                    OptionC: optionC,
                    OptionD: optionD,
                    SelectedOption: selectedOption ? selectedOption.value : "0"
                };
                axios.post("/Nishant/Qubits/AddAnswer", obj).then(async function (r) {
                    //toast.success('Answer successfully Submitted');
                    await GetCheckByChatGPT(r);
                })
            }
            else if (IsMcq == "1" && ClientId != "") {
                if (IsMulti == "1" && (optionA == true || optionB == true || optionC == true || optionD == true)) {
                    var obj = {
                        TId: TestId,
                        QId: QId,
                        empId: ClientId,
                        Ctext: Answertext,
                        isMcq: IsMcq,
                        isMulti: IsMulti,
                        OptionA: optionA,
                        OptionB: optionB,
                        OptionC: optionC,
                        OptionD: optionD,
                        SelectedOption: selectedOption ? selectedOption.value : "0"
                    };
                    axios.post("/Nishant/Qubits/AddAnswer", obj).then(function (r) {

                    })
                }
                else if (IsMulti == "1" && !(optionA == true || optionB == true || optionC == true || optionD == true)) {
                    toast.error('Please select atleast one option');
                }
                else if (IsMulti == "0" && selectedOption) {
                    var obj = {
                        TId: TestId,
                        QId: QId,
                        empId: ClientId,
                        Ctext: Answertext,
                        isMcq: IsMcq,
                        isMulti: IsMulti,
                        OptionA: optionA,
                        OptionB: optionB,
                        OptionC: optionC,
                        OptionD: optionD,
                        SelectedOption: selectedOption ? selectedOption.value : "0"
                    };
                    axios.post("/Nishant/Qubits/AddAnswer", obj).then(function (r) {
                        ;
                    })
                }
                else {
                    toast.error('Please select one option');
                }
            }
            else toast.error('Please add some text');
        }
        else toast.error('Invalid Login');
    }

    const GetCheckByChatGPT = async (obj) => {
        let isCorrected = false;
        var str;

        if (obj.data[0].prevMarkedAsCorrect == "") {
            var str = `Act as a quiz master. Your goal is to check a student's answer to the question provided below based on your expertise.${(obj.data[0].QuestionCourseName != "" ? "The course name is '" + obj.data[0].QuestionCourseName + "'. " : "")}. <<${ReactDOMServer.renderToStaticMarkup(obj.data[0].QuestionText)}:>>. Here is the student’s answer <<${ReactDOMServer.renderToStaticMarkup(obj.data[0].Answer)}>>.
            Your reply's first word must be either “Correct”, “Incorrect”, or “Not Sure” only. Ensure that if our trainer has marked an answer correct before, use only that for checking in your output. Eliminate incorrect answers by using answers our trainer has previously marked incorrect. Only if you mark any question incorrect, give 2-4 lines on why it was marked incorrect and separately provide the correct answer concisely. You must be at least 90% confident when answering “Correct” or “Incorrect” otherwise respond with “Not Sure”`

            var d = {
                str: str
            }

            const response = await axios.post('/Nishant/Qubits/GetGPTResponse', d);
            UpdateAnswerStatus(response.data, obj.data[0]);

            // axios.post('/Nishant/Qubits/GetGPTResponse', d).then(function (response) {
            //     UpdateAnswerStatus(response.data, obj.data[0]);
            // })
        }
        else {
            for (let i = 0; i < obj.data[0].prevMarkedAsCorrect.split('$%&^').length; i++) {
                str = `Act as a quiz master. Your goal is to check a student's answer to the question provided below based on your expertise.${(obj.data[0].QuestionCourseName != "" ? "The course name is '" + obj.data[0].QuestionCourseName + "'. " : "")}. <<${ReactDOMServer.renderToStaticMarkup(obj.data[0].QuestionText)}:>>. Here is the student’s answer <<${ReactDOMServer.renderToStaticMarkup(obj.data[0].Answer)}:>>.
                ${(obj.data[0].prevMarkedAsCorrect != "" ? "Our trainer has marked this/these answers correct before <<'" + obj.data[0].prevMarkedAsCorrect.split('$%&^')[i] + "'>>. " : "")} 
                Your reply's first word must be either “Correct”, “Incorrect”, or “Not Sure” only. Ensure that if our trainer has marked an answer correct before, use only that for checking in your output. Eliminate incorrect answers by using answers our trainer has previously marked incorrect. Only if you mark any question incorrect, give 2-4 lines on why it was marked incorrect and separately provide the correct answer concisely. You must be at least 90% confident when answering “Correct” or “Incorrect” otherwise respond with “Not Sure”`

                var d = {
                    str: str
                }


                const response = await axios.post('/Nishant/Qubits/GetGPTResponse', d);

                if (response.data.toLowerCase().startsWith("Correct") || i == obj.data[0].prevMarkedAsCorrect.split('$%&^').length - 1) {
                    UpdateAnswerStatus(response.data, obj.data[0]);
                    break;
                }

                // axios.post('/Nishant/Qubits/GetGPTResponse', d).then(function (response) {
                //     if(response.data.toLowerCase().startsWith("Correct") || i==obj.data[0].prevMarkedAsCorrect.split('$%&^').length-1)
                //     {
                //         UpdateAnswerStatus(response.data, obj.data[0]);
                //     }

                // })
                // if(obj.data[0].prevMarkedAsCorrect.split('$%&^')[i].toLowerCase().startsWith("Correct"))
                // {
                //     isCorrected=true;
                //     break;
                // }
            }
        }
        console.log(str)
    }

    const UpdateAnswerStatus = (d, TestData) => {
        var obj = {
            Type: 32,
            Id: TestData.QId,
            GPTResponse: d
        };
        axios.post('/Nishant/Qubits/UpdateAnswerStatusUsingChatGPT', obj).then(function (response) {

        })
    }

    const SendStatusToStatus = () => {
        if (EmailRegex.test(ClientId)) {
            var obj = {
                Type: 10,
                TId: TestId,
                empId: ClientId,
            };
            axios.post('/Nishant/Qubits/GetAllSubjectiveQuestionForClientPost', obj).then(function (response) {
                if (response.data != null) {
                    var QDetail = ReactDOMServer.renderToStaticMarkup(ReturnHtml(response.data));
                    console.log(QDetail);
                    var SendTo = ClientId;
                    SendMail1(response.data[0].UserEmail, SendTo, response.data[0].UserEmail, 'nishant.sinha@koenig-solutions.com', 'Test on ' + response.data[0].TestName + " via Qubits Live", QDetail);
                    alert("Report sent");
                }
            })
        }
        else toast.error('Invalid Login');
    }

    async function SendMail1(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            await axios.post("/Nishant/sendemail", obj)
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const ReturnHtml = (data) => {
        return (
            <>
                <span>Following is your test result </span><br></br><br></br>
                <table style={{ width: "100%", color: "#212529", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>SL No</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Question</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Answer</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Status</td>
                    </tr>
                    {data.map((d, i) =>
                        <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{i + 1}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                                <Interweave content={d.Question} />
                                {(d.IsMcq == "1") ? <>
                                    <span className="option" style={{ display: (d.OptionA == '') ? 'none' : 'flex' }}>
                                        <div className="ans-content">
                                            <div style={{ color: (d.TagA) ? 'green' : '' }}><Interweave content={d.OptionA} /></div>
                                        </div>
                                    </span>
                                    <span className="option" style={{ display: (d.OptionB == '') ? 'none' : 'flex' }}>
                                        <div className="ans-content">
                                            <div style={{ color: (d.TagB) ? 'green' : '' }}><Interweave content={d.OptionB} /></div>
                                        </div>
                                    </span>
                                    <span className="option" style={{ display: (d.OptionC == '') ? 'none' : 'flex' }}>
                                        <div className="ans-content">
                                            <div style={{ color: (d.TagC) ? 'green' : '' }}><Interweave content={d.OptionC} /></div>
                                        </div>
                                    </span>
                                    <span className="option" style={{ display: (d.OptionD == '') ? 'none' : 'flex' }}>
                                        <div className="ans-content">
                                            <div style={{ color: (d.TagD) ? 'green' : '' }}><Interweave content={d.OptionD} /></div>
                                        </div>
                                    </span>
                                </> : <></>}
                                {(d.IsCorrect == 0 || d.IsCorrect == null) ? <>
                                    <hr></hr>
                                    <span>Correct Answer: {d.CorrectAnswer == 'N/A' ? 'N/A' : <b>{d.CorrectAnswer}</b>}</span>
                                </> : <></>}
                            </td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}><Interweave content={d.Answer1} /></td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem", color: GetColorOfStatus(d.AnswerStatus) }}>{d.AnswerStatus}</td>
                        </tr>
                    )}
                </table>
            </>
        )
    }

    const GetColorOfStatus = (Id) => {
        switch (Id) {
            case "Correct": return "green";
            case "Incorrect": return "red";
            case "Partially Correct": return "yellow";
            default: return "black";
        }
    }

    const StatusName = (Id) => {
        switch (Id) {
            case 1: return "Correct";
            case 0: return "Incorrect";
            case 2: return "Partailly Correct";
            default: return "Not yet checked";
        }
    }

    const StatusCSSClassName = (Id) => {
        switch (Id) {
            case 1: return "text-success";
            case 0: return "text-danger";
            case 2: return "text-warning";
            default: return "";
        }
    }

    const DiscussWithTrainer = (Id, QId, IsChecked) => {
        var obj = {
            Type: 28,
            TId: TestId,
            Id: Id,
            QId: QId,
            empId: ClientId,
            value: IsChecked
        };
        axios.post('/Nishant/Qubits/UpdateStatusOfTrainerDiscussion', obj).then(function (response) {
            if (response.data != null) {
                GetAllSubjectiveQuestion(TestId);
            }
        })
    }

    return (
        <>
            <div>
                {/* <div className='headerStrip'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='koenig_logo'>
                                    <img src={koenig_logos} alt='koenigLogo' />
                                </div>
                            </div>
                            <div className='col-md-4'>
<p> {HeadingText}</p>
                            </div>
                            <div className='col-md-4'>
                                <div className='qubites_logo'>
                                    <img src={qubites_logos} style={{ height: "50px", float: "right" }} alt='qubitesLogo' />
                                </div>

                            </div>
                        </div>


                    </div>
                </div> */}

                <div id="divIdToPrint" style={{
                    flex: 1,
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fafafa",
                }}>
                    {/* header */}
                    <div style={{
                        height: "10vh", padding:"0px 30px",
                        backgroundColor: "#0b445c", display:"flex", alignItems:"center", justifyContent:"center", justifyContent:"space-between"
                    }}>
 <div className='koenig_logo'>
                                    <img src={koenig_logos} style={{ height: "65px", float: "right" }} alt='koenigLogo' />
                                </div>

                        <span style={{
                            height: "10vh", margin: 0, padding: 0, justifyContent: 'center',
                            alignItems: 'center', display: "flex", color: 'white', fontSize: '25px'
                        }}>
                            {HeadingText}
                        </span>
                        <div className='qubites_logo'>
                                    <img src={qubites_logos} style={{ height: "70px", float: "right" }} alt='qubitesLogo' />
                                </div>
                        <span style={{
                            position: 'absolute', right: 20, top: 2,
                            height: "10vh", margin: 0, padding: 0, justifyContent: 'flex-end',
                            alignItems: 'center', display: "flex"
                        }}>
                        </span>
                    </div>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>

                        <span style={{ display: main_loader ? 'block' : 'none' }}>
                            <CircularProgress size={80} color="secondary" />
                        </span>
                        <div style={{
                            height: "90vh",
                            display: isLive ? 'flex' : 'none',
                            flexDirection: 'column',
                            backgroundColor: '#fafafa', width: '50vw',
                            alignItems: 'center'
                        }}>
                            <span style={{ color: '#35b58e', fontWeight: 'bolder', fontSize: 35, cursor: 'pointer', paddingTop: 250, textAlign: 'center' }} > Your Trainer will start the test soon!!!</span>
                        </div>
                        <div className="m-3 my-test-outer student-test container-fluid" style={{ display: !isLive ? 'flex' : 'none', }}>
                            <Button style={{ display: (isTestStarted ? "inline-block" : "none") }} color='blue' disabled={!isTestStarted} className="mt-2 mb-2 send-report-btn2 " size="small" onClick={() => {
                                SendStatusToStatus()
                            }}>
                                Send Me My Report
                            </Button>
                            <div id="spTimer" style={{ marginLeft: '15em' }}></div>
                            <div id="MainContent" className="w-100"></div>
                            <br></br>
                            <div style={{
                                height: "90vh",
                                display: IsTestFinished ? 'flex' : 'none',
                                flexDirection: 'column',
                                backgroundColor: '#fafafa', width: '50vw',
                                alignItems: 'center'
                            }}>
                                <span style={{ color: '#35b58e', fontWeight: 'bolder', fontSize: 35, cursor: 'pointer', paddingTop: 250, textAlign: 'center' }} > Test Finished by Test Maker!!!</span>
                            </div>

                            <div className="w-10" style={{ display: (!IsTestFinished && isTestStarted ? "flex" : "none") }}>
                                <Table id={'tblQues'} celled singleLine className="text-wrap test-table">
                                    
                                    <Table.Body>
                                    {AllSubjective.length > 0 ? (
    <Table.Row>
        <Table.Cell
            data-title="CorrectRatio"
            style={{ fontSize: 14, fontWeight: 'bold',textAlign: 'center' }}
            title={AllSubjective[0].CorrectRatio}
        >
            {AllSubjective[0].CorrectRatio}
        </Table.Cell>
    </Table.Row>
) : (
    <Table.Row>
        <Table.Cell colSpan={4}></Table.Cell>
    </Table.Row>
)}
                                    </Table.Body>
                                </Table>
                            </div>


                            <div className="w-100" style={{ display: (!IsTestFinished && isTestStarted ? "flex" : "none") }}>
                                <Table id={'tblQues'} celled singleLine className="text-wrap test-table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center', width: '40' }}>#</Table.HeaderCell>
                                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left', width: '60%' }}>Question</Table.HeaderCell>
                                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left', width: '30%' }}>Your Answer</Table.HeaderCell>
                                            <Table.HeaderCell style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}>Status</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {AllSubjective.length > 0 ? AllSubjective.map((row, i) => (
                                            <Table.Row>
                                                <Table.Cell data-title="# "
                                                    style={{ fontSize: 14, fontWeight: 'bold' }}
                                                    title={i + 1}
                                                >
                                                    {i + 1}
                                                </Table.Cell>
                                                <Table.Cell data-title="Question"
                                                    style={{ fontSize: 14, fontWeight: 'bold' }}
                                                    title={row.Question}
                                                >
                                                    <Interweave content={row.Question} />
                                                    <div className="ans-options" id={"AnswerStatus_" + row.QuestionId} style={{ display: (row.IsMcq == '1') ? 'block' : 'none' }}>
                                                        <input type='hidden' id={"IsMcq_" + row.QuestionId} value={row.IsMcq} />
                                                        <input type='hidden' id={"IsMulti_" + row.QuestionId} value={row.multi} />
                                                        <span className="option" style={{ display: (row.OptionA == '') ? 'none' : 'flex' }}>
                                                            <div className="ans-radio">
                                                                <Checkbox id={'Chk1_' + row.QuestionId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                                                />
                                                                <input type="radio" style={{
                                                                    display: (row.multi == '1') ? 'none' : 'flex'
                                                                    , height: 30
                                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                                }} name={row.QuestionId} value={1}
                                                                />
                                                            </div>
                                                            <div className="ans-content">
                                                                <div><Interweave content={row.OptionA} /></div>
                                                            </div>
                                                        </span>
                                                        <span className="option" style={{ display: (row.OptionB == '') ? 'none' : 'flex' }}>
                                                            <div className="ans-radio">
                                                                <Checkbox id={'Chk2_' + row.QuestionId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                                                />
                                                                <input type="radio" style={{
                                                                    display: (row.multi == '1') ? 'none' : 'flex'
                                                                    , height: 30
                                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                                }} name={row.QuestionId} value={2}
                                                                />
                                                            </div>
                                                            <div className="ans-content">
                                                                <div><Interweave content={row.OptionB} /></div>
                                                            </div>
                                                        </span>
                                                        <span className="option" style={{ display: (row.OptionC == '') ? 'none' : 'flex' }}>
                                                            <div className="ans-radio">
                                                                <Checkbox id={'Chk3_' + row.QuestionId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                                                />
                                                                <input type="radio" style={{
                                                                    display: (row.multi == '1') ? 'none' : 'flex'
                                                                    , height: 30
                                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                                }} name={row.QuestionId} value={3}
                                                                />
                                                            </div>
                                                            <div className="ans-content">
                                                                <div><Interweave content={row.OptionC} /></div>
                                                            </div>
                                                        </span>
                                                        <span className="option" style={{ display: (row.OptionD == '') ? 'none' : 'flex' }}>
                                                            <div className="ans-radio">
                                                                <Checkbox id={'Chk4_' + row.QuestionId} style={{ display: (row.multi == '0') ? 'none' : 'flex' }}
                                                                />
                                                                <input type="radio" style={{
                                                                    display: (row.multi == '1') ? 'none' : 'flex'
                                                                    , height: 30
                                                                    , marginRight: 20, marginTop: 5, marginLeft: 12
                                                                }} name={row.QuestionId} value={4}
                                                                />
                                                            </div>
                                                            <div className="ans-content">
                                                                <div><Interweave content={row.OptionD} /></div>
                                                            </div>
                                                        </span>
                                                        {/* <div className="text-center p-3"><a className="btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Explanation</a></div> */}
                                                        {/* {
                                (obj.multi == '1') ? <div className="text-center p-3"><a className="badge btn-outline-primary btn text-primary p-2" onClick={(e) => ShowCorrectAnswer(obj.QuesId)}>Done</a></div> : ''
                            } */}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell data-title="Your Answer"
                                                    style={{ fontSize: 14, fontWeight: 'bold' }}
                                                    title='Submit Your Answer'
                                                >
                                                    {
                                                        (row.Answer == null && row.IsMcq != '1') ?
                                                            <>
                                                                <TextField id={'txtAnswer_' + row.QuestionId} className="formFIll mt-2" size="small" multiline label="Enter Answer" variant="outlined" rows={4} style={{ width: '100%' }} />
                                                            </>
                                                            : <span className="text-wrap">{row.Answer}</span>
                                                    }
                                                    {
                                                        // LoginType != 'HR' ? <>
                                                        //     <hr style={{ display: (row.IsMcq == '1') ? 'none' : 'block' }}></hr>
                                                        //     <input type='checkbox' style={{ top: '2px', position: 'relative' }} checked={row.IsWantToDiscusswithTrainer}
                                                        //         onChange={(e) => DiscussWithTrainer(row.Id, row.QuestionId, e.target.checked)}></input>
                                                        //     <span className='ml-2'> I want to discuss with my trainer.</span>
                                                        // </> : <></>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell data-title="Status: "
                                                    style={{ fontSize: 14, fontWeight: 'bold' }}
                                                    title="Status"
                                                >
                                                    {
                                                        row.Answer == null ?
                                                            <Button color='teal' size="small" onClick={(e) => SubmitAnswer(row.QuestionId)}>
                                                                Submit
                                                            </Button> :
                                                            <>
                                                                <span className={StatusCSSClassName(row.IsCorrect)}>{StatusName(row.IsCorrect)}</span>
                                                                {row.FeedbackFromTrainer == null ? <></> :
                                                                    <><br></br><br></br><span>Feedback from Test Taker:
                                                                        <span className={StatusCSSClassName(row.IsCorrect) + " ml-2"}>{row.FeedbackFromTrainer}</span></span></>}
                                                            </>
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                            : <Table.Row>
                                                <Table.Cell colSpan={4}>No Question Added for this Test.</Table.Cell>
                                            </Table.Row>
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default TrainerSubjectiveTest;